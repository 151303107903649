import { EditIcon } from '@chakra-ui/icons'
import { IconButton, Tooltip } from '@chakra-ui/react'

import { IFormField } from '../../../../../../features/dynamicForm/DynamicForm.interfaces'
import {
    buildListOfKeyValues,
    ColumnActionConfiguration,
    toCamelCase,
} from '../../../../../../utils/functions.utils'
import {
    ProductRuleDTO,
    ProductRuleType,
} from '../../../../../../utils/types/types'

export function editContractTemplateRuleFormFields(
    rule: Partial<ProductRuleDTO>
): IFormField<Partial<ProductRuleDTO>>[] {
    if (
        rule.ruleType === ProductRuleType.IncludeCreationFee ||
        rule.ruleType === ProductRuleType.IsVariableInterest
    ) {
        return [
            {
                slug: 'ruleType',
                label: 'ruleType',
                inputProps: {
                    type: 'select',
                    isRequired: true,
                    disabled: true,
                },
                optionsKeysValues:
                    buildListOfKeyValues<typeof ProductRuleType>(
                        ProductRuleType
                    ),
                value: rule.ruleType,
            },
            {
                slug: 'defaultValue',
                label: 'defaultValue',
                inputProps: { type: 'select', isRequired: true },
                optionsKeysValues: [
                    ['true', 'yes'],
                    ['false', 'No'],
                ],
                value: rule.defaultValue,
            },
            {
                slug: 'hidden',
                label: 'hidden',
                inputProps: { type: 'boolean' },
                value: rule.hidden,
            },
            {
                slug: 'locked',
                label: 'locked',
                inputProps: { type: 'boolean' },
                value: rule.locked,
            },
            {
                slug: 'customText',
                label: 'customText',
                inputProps: { type: 'text' },
                value: rule.customText,
            },
        ]
    }

    const isReferenceStringRule = [
        ProductRuleType.ExternalReferenceText,
        ProductRuleType.ExternalReferenceString1,
        ProductRuleType.ExternalReferenceString2,
        ProductRuleType.ExternalReferenceString3,
        ProductRuleType.ExternalReferenceString4,
    ].includes(rule?.ruleType as any)

    if (isReferenceStringRule) {
        return [
            {
                slug: 'ruleType',
                label: 'ruleType',
                inputProps: {
                    type: 'select',
                    isRequired: true,
                    disabled: true,
                },
                optionsKeysValues:
                    buildListOfKeyValues<typeof ProductRuleType>(
                        ProductRuleType
                    ),
                value: rule.ruleType,
            },
            {
                slug: 'defaultValue',
                label: 'defaultValue',
                inputProps: {
                    type: 'string',
                    isRequired: true,
                },
                value: rule.defaultValue,
                enableDecimal: false,
            },
            {
                slug: 'hidden',
                label: 'hidden',
                inputProps: { type: 'boolean' },
                value: rule.hidden,
            },
            {
                slug: 'locked',
                label: 'locked',
                inputProps: { type: 'boolean' },
                value: rule.locked,
            },
            {
                slug: 'customText',
                label: 'customText',
                inputProps: { type: 'text' },
                value: rule.customText,
            },
        ]
    }

    return [
        {
            slug: 'targeted',
            label: 'targeted',
            inputProps: { type: 'boolean' },
        },
        {
            slug: 'ruleType',
            label: 'ruleType',
            inputProps: {
                type: 'select',
                isRequired: true,
                disabled: true,
            },
            optionsKeysValues:
                buildListOfKeyValues<typeof ProductRuleType>(ProductRuleType),
            value: rule.ruleType,
        },
        {
            slug: 'defaultValue',
            label: 'defaultValue',
            inputProps: {
                type: isReferenceStringRule ? 'string' : 'number',
                isRequired: true,
            },
            value: rule.defaultValue,
            enableDecimal: false,
        },
        {
            slug: 'hidden',
            label: 'hidden',
            inputProps: { type: 'boolean' },
            value: rule.hidden,
        },
        {
            slug: 'locked',
            label: 'locked',
            inputProps: { type: 'boolean' },
            value: rule.locked,
        },
        {
            slug: 'minimumValue',
            label: 'minimumValue',
            inputProps: { type: 'number' },
            value: rule.minimumValue,
        },
        {
            slug: 'maximumValue',
            label: 'maximumValue',
            inputProps: { type: 'number' },
            value: rule.maximumValue,
        },
        {
            slug: 'customText',
            label: 'customText',
            inputProps: { type: 'text' },
            value: rule.customText,
        },
    ]
}

export const columnsConfig = (
    translate: Function,
    onEdit: Function,
    formatValue: Function
) => [
    {
        field: 'targeted',
        headerName: translate('targeted'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'ruleType',
        headerName: translate('type'),
        filter: 'agTextColumnFilter',
        valueGetter: (params: any) =>
            params?.data?.ruleType
                ? translate(toCamelCase(ProductRuleType[params.data.ruleType]))
                : '',
    },
    {
        field: 'hidden',
        headerName: translate('hidden'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'locked',
        headerName: translate('locked'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'defaultValue',
        headerName: translate('defaultValue'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'minimumValue',
        headerName: translate('minimumValue'),
        filter: 'agTextColumnFilter',
        valueFormatter: (params: any) => formatValue(params, 'minimumValue'),
    },
    {
        field: 'maximumValue',
        headerName: translate('maximumValue'),
        filter: 'agTextColumnFilter',
        valueFormatter: (params: any) => formatValue(params, 'maximumValue'),
    },
    {
        field: 'customText',
        headerName: translate('customText'),
        filter: 'agTextColumnFilter',
        flex: 1,
    },
    {
        ...ColumnActionConfiguration(
            translate,
            (params: any) => (
                <Tooltip label={translate('edit')} placement="top" hasArrow>
                    <IconButton
                        mr={2}
                        aria-label="Edit"
                        size="sm"
                        variant={'outline'}
                        onClick={() => onEdit(params?.data)}
                        icon={<EditIcon />}
                    />
                </Tooltip>
            ),
            100
        ),
    },
]
