import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import DynamicGrid from '../../../pages/demo/DynamicAGGrid.component'
import { useNumberFormatterContext } from '../../../services/contexts/NumberFormatter.context'
import { CustomServiceType } from '../../../services/contract/Contract.services.config'
import { ColumnActionConfiguration } from '../../../utils/functions.utils'

interface IServicesTable {
    services: CustomServiceType[]
    tableActions: Function
}

export default function AssetServicesTable({
    services,
    tableActions,
}: IServicesTable): ReactElement {
    const translate = useTranslation().t
    const { formatValue } = useNumberFormatterContext()
    const headers = [
        {
            headerName: translate('service'),
            filter: true,
            field: 'description',
        },
        {
            headerName: translate('id'),
            filter: true,
            field: 'baseServiceNumber',
        },
        {
            headerName: translate('vat'),
            filter: true,
            field: 'vatPercentage',
            valueFormatter: (params: any) =>
                `${formatValue(params.data.vatPercentage)} %`,
        },
        {
            headerName: translate('fixedValueOverride'),
            filter: true,
            field: 'fixedValueOverride',
            valueFormatter: (params: any) =>
                formatValue(params.data.fixedValueOverride),
        },
        {
            headerName: translate('currentServicePrice'),
            filter: true,
            field: 'baseServiceValues',
            valueGetter: (params: any) =>
                formatValue(
                    params.data.baseServiceValues.find(
                        (item: any) => item.isCurrent
                    )?.value || 0
                ),
        },
        {
            ...ColumnActionConfiguration(translate, (params: any) =>
                tableActions(params.data)
            ),
        },
    ]
    return (
        <DynamicGrid
            columns={headers}
            rowData={services}
            tableId="assetServicesTable"
        />
    )
}
