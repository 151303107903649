import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Button, FormControl, FormLabel, Input, Stack } from '@chakra-ui/react'

import ErrorBoundaryWrapper from '../../../../../features/errorBoundary/ErrorBoundary.feature'
import { useLoading } from '../../../../../services/contexts/Loading.context'
import { useContractService } from '../../../../../services/contract/Contract.services'
import { CustomServiceType } from '../../../../../services/contract/Contract.services.config'
import { ContractAssetDTO } from '../../../../../utils/types/types'
import { updateContractAssetService } from './ContractAssetServices.api'

interface IEditContractAssetService {
    asset: ContractAssetDTO
    service: CustomServiceType
    onClose: Function
    setSelectedAsset: Function
}

export default function EditContractAssetService({
    asset,
    service,
    onClose,
    setSelectedAsset,
}: IEditContractAssetService): ReactElement {
    const { getContract } = useContractService()
    const { globalLoading, stopGlobalLoading } = useLoading()
    const translate = useTranslation().t
    const [overrideValue, setOverrideValue] = useState(
        service?.fixedValueOverride
    )

    const handleOverrideChange = (value: number): void => {
        setOverrideValue(value)
    }

    const editService = async (): Promise<void> => {
        const loadingID = globalLoading()

        const newAssetServices =
            asset?.assetServices?.map((assetService) => {
                if (
                    assetService.baseServiceNumber === service.baseServiceNumber
                ) {
                    return {
                        ...assetService,
                        fixedValueOverride: overrideValue,
                    }
                }
                return assetService
            }) || []
        setSelectedAsset({ ...asset, assetServices: [...newAssetServices] })

        if (asset?.assetNumber) {
            await updateContractAssetService(
                asset.assetNumber,
                [...newAssetServices],
                () => getContract()
            )
        }
        stopGlobalLoading(loadingID)
        onClose()
    }

    return (
        <ErrorBoundaryWrapper id="edit-contract-asset-service-error">
            <Stack spacing={8} py={4}>
                <FormControl>
                    <FormLabel>{`${service.description} - ${translate(
                        'fixedValueOverride'
                    )}`}</FormLabel>
                    <Input
                        type="number"
                        value={overrideValue}
                        onChange={(e: any): void => {
                            handleOverrideChange(e.target.value)
                        }}
                    />
                </FormControl>
            </Stack>
            <Button
                leftIcon={<ChevronLeftIcon />}
                mr={4}
                onClick={(): void => onClose()}
            >
                {translate('back')}
            </Button>
            <Button onClick={editService}>{translate('editService')}</Button>
        </ErrorBoundaryWrapper>
    )
}
