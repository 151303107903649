import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import './DynamicAGGrid.component.scss'

import { AgGridReact } from 'ag-grid-react'
import { ReactElement, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ModuleRegistry } from '@ag-grid-community/core'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import {
    Box,
    Flex,
    Input,
    Spinner,
    Tag,
    TagCloseButton,
    TagLabel,
    useColorModeValue,
} from '@chakra-ui/react'

import { formatDate } from '../../utils/localization/culture.utils'
import { getTableLocale } from './translation'

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    MenuModule,
    SetFilterModule,
    FiltersToolPanelModule,
])

export default function DynamicGrid({
    externalGridRef,
    tableId,
    key,
    columns,
    rowData,
    pagination = true,
    rowSelection = 'multiple',
    masterDetail = false,
    detailCellRendererParams = {},
    rowMultiSelectWithClick = true,
    onGridReady,
    onSelectionChanged,
    cellValueChanged,
    boxProps,
    headers,
    rowHeight,
    enableSearch = true,
}: any): ReactElement {
    const internalGridRef = useRef<any>()
    const gridRef = externalGridRef || internalGridRef
    const translate = useTranslation().t
    const [gridApi, setGridApi] = useState<any>(null)
    const [filterTags, setFilterTags] = useState<any[]>([])

    const color = useColorModeValue('primary.500', 'secondary.500')

    const onGridReadyModified = (params: { api: any }) => {
        setGridApi(params.api) // Store the grid API on grid ready
        if (onGridReady) {
            onGridReady(params)
        }
    }

    const handleSearchChange = (event: { target: { value: any } }) => {
        gridApi && gridApi.setQuickFilter(event.target.value)
    }

    const parseFilterModel = (filterModel: any) => {
        const newFilterTags = Object.keys(filterModel).map((field) => {
            const filter = filterModel[field]
            let conditionStrings = []
            if (filter.operator) {
                conditionStrings = filter.conditions.map(
                    (condition: any) =>
                        condition.filter ||
                        (condition.dateFrom
                            ? formatDate(condition.dateFrom)
                            : '') ||
                        condition.name ||
                        ''
                )
                return {
                    field: translate(field),
                    value: conditionStrings.join(` ${filter.operator} `),
                }
            }
            conditionStrings.push(
                filter.filter ||
                    (filter.dateFrom ? formatDate(filter.dateFrom) : '') ||
                    filter.name ||
                    ''
            )
            return {
                field: translate(field),
                value: conditionStrings.join(''),
            }
        })
        return newFilterTags
    }

    const onFilterChanged = () => {
        const filterModel = gridApi.getFilterModel()
        const newFilterTags = parseFilterModel(filterModel)
        setFilterTags(newFilterTags)
    }

    const removeFilter = (field: string) => {
        gridApi.getFilterInstance(field, (filterInstance: any) => {
            filterInstance.setModel(null)
            gridApi.onFilterChanged()
        })
        setFilterTags(filterTags.filter((tag) => tag.field !== field))
    }

    const resetAllFilters = () => {
        gridApi.setFilterModel(null)
        gridApi.onFilterChanged()
        setFilterTags([])
    }

    return (
        <Box key={key} id={tableId} width="100%">
            <Flex
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
            >
                {enableSearch && (
                    <Input
                        placeholder={translate('search')}
                        size="md"
                        w={250}
                        onChange={handleSearchChange}
                    />
                )}
                <Flex alignItems="center">
                    {filterTags.map((tag, index) => (
                        <Tag
                            size="lg"
                            key={index}
                            borderRadius={'lg'}
                            px={3}
                            py={1}
                            fontSize={'small'}
                            fontWeight={'semibold'}
                            colorScheme="blue"
                            background={'blue.100'}
                            color={'blue.800'}
                            m={1}
                        >
                            <TagLabel>{`${tag.field}: ${tag.value}`}</TagLabel>
                            <TagCloseButton
                                onClick={() => removeFilter(tag.field)}
                            />
                        </Tag>
                    ))}
                    {filterTags.length > 0 && (
                        <Tag
                            size="lg"
                            borderRadius={'lg'}
                            px={3}
                            py={1}
                            fontSize={'small'}
                            fontWeight={'semibold'}
                            colorScheme="red"
                            background={'red.100'}
                            color={'red.800'}
                            m={1}
                            onClick={resetAllFilters}
                            cursor="pointer"
                        >
                            <TagLabel>{translate('reset')}</TagLabel>
                        </Tag>
                    )}
                </Flex>
                <div>{headers}</div>
            </Flex>
            <Box width="100%" overflow="hidden">
                <div
                    className={useColorModeValue(
                        'ag-theme-quartz',
                        'ag-theme-quartz-dark'
                    )}
                    style={{ width: '100%', minHeight: '350px' }} // Minimum height set here
                    {...boxProps}
                >
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columns}
                        masterDetail={masterDetail}
                        detailCellRendererParams={detailCellRendererParams}
                        animateRows={true}
                        paginationPageSize={20}
                        paginationPageSizeSelector={[10, 20, 30]}
                        loadingOverlayComponent={() => (
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color={color}
                                size="xl"
                                position="absolute"
                                top="calc(50% - 16px)"
                                left="calc(50% - 16px)"
                            />
                        )}
                        rowMultiSelectWithClick={rowMultiSelectWithClick}
                        onGridReady={onGridReadyModified}
                        gridOptions={{
                            pagination,
                            rowSelection,
                            suppressRowClickSelection: true,
                            rowMultiSelectWithClick,
                            includeHiddenColumnsInQuickFilter: true,
                            localeText: getTableLocale(translate),
                        }}
                        onFilterChanged={onFilterChanged}
                        onSelectionChanged={onSelectionChanged}
                        onCellValueChanged={cellValueChanged}
                        domLayout="autoHeight" // Allows the grid to grow based on content
                        sideBar={{
                            toolPanels: [
                                {
                                    id: 'columns',
                                    labelDefault: 'Columns',
                                    labelKey: 'columns',
                                    iconKey: 'columns',
                                    toolPanel: 'agColumnsToolPanel',
                                },
                                {
                                    id: 'filters',
                                    labelDefault: 'Filters',
                                    labelKey: 'filters',
                                    iconKey: 'filter',
                                    toolPanel: 'agFiltersToolPanel',
                                },
                            ],
                            defaultToolPanel: '', // No tool panel open by default
                        }}
                    />
                </div>
            </Box>
        </Box>
    )
}
