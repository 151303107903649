import camelCase from 'lodash/camelCase'

import {
    CALCULATION_TYPE_PAIRS,
    USAGE_TYPE_PAIRS,
} from '../../features/addAsset/addAsset.feature.config'
import {
    buildListOfKeyValues,
    getSpecificKeyFromObject,
} from '../../utils/functions.utils'
import {
    AssetTypeBO,
    FuelType,
    GetBaseAssetDetail,
    LocationDTO,
} from '../../utils/types/types'
import { IFormField } from '../../features/dynamicForm/DynamicForm.interfaces'

export function DefaultInventoryFormFields(
    baseAsset: GetBaseAssetDetail,
    locations: LocationDTO[],
    assetTypes: AssetTypeBO[]
): IFormField<GetBaseAssetDetail>[] {
    function buildlist() {
        const options: Array<Array<string | number>> = []
        assetTypes.forEach((item) => {
            if (item.isActive) {
                options.push([item.id, item.name as string])
            }
        })
        return options
    }
    return [
        {
            slug: 'originalPurchaseValue',
            label: 'originalPurchaseValue',
            inputProps: { type: 'number' },
            value: baseAsset?.originalPurchaseValue || 0,
        },
        {
            slug: 'make',
            label: 'make',
            inputProps: { type: 'text' },
            value: baseAsset?.make || '',
        },
        {
            slug: 'model',
            label: 'model',
            inputProps: { type: 'text' },
            value: baseAsset?.model || '',
        },
        {
            slug: 'modelYear',
            label: 'modelYear',
            inputProps: { type: 'text' },
            value: baseAsset?.modelYear || '',
        },
        {
            slug: 'variant',
            label: 'variant',
            inputProps: { type: 'text' },
            value: baseAsset?.variant || '',
        },
        {
            slug: 'location.locationNumber' as any,
            label: 'location',
            skipTranslation: true,
            value: baseAsset?.location?.locationNumber || '',
            inputProps: { type: 'select' },
            isValueString: true,
            optionsKeysValues: [
                ...locations.map((location) => [
                    location.locationNumber,
                    location.locationName,
                ]),
            ],
        },
        {
            slug: 'serialNumber',
            label: 'serialNumber',
            inputProps: { type: 'text' },
            value: baseAsset?.serialNumber || '',
        },
        {
            slug: 'assetType.id' as any,
            label: 'assetType',
            value: (baseAsset.assetType as any)?.id || '', // TODO: Remove any
            inputProps: { type: 'select' },
            optionsKeysValues: buildlist(),
            skipTranslation: true,
        },
    ]
}

export function CarFields(
    baseAsset: GetBaseAssetDetail
): IFormField<GetBaseAssetDetail>[] {
    return [
        {
            slug: 'registrationNumber',
            label: 'registrationNumber',
            inputProps: { type: 'text' },
            value: baseAsset?.registrationNumber || '',
        },
        {
            slug: 'vin',
            label: 'vin',
            inputProps: { type: 'text' },
            value: baseAsset?.vin || '',
        },
        {
            slug: 'exteriorColor',
            label: 'exteriorColor',
            inputProps: { type: 'color' },
            value: baseAsset?.exteriorColor || '',
        },
        {
            slug: 'interiorColor',
            label: 'interiorColor',
            inputProps: { type: 'color' },
            value: baseAsset?.interiorColor || '',
        },
        {
            slug: 'power',
            label: 'power',
            inputProps: { type: 'text' },
            value: baseAsset?.power || '',
        },
        {
            slug: 'fuelType',
            label: 'fuelType',
            inputProps: { type: 'select' },
            optionsKeysValues: buildListOfKeyValues<typeof FuelType>(FuelType),
            value: camelCase(
                getSpecificKeyFromObject<typeof FuelType>(
                    FuelType,
                    baseAsset?.fuelType || ''
                )
            ),
        },
        {
            slug: 'firstRegistrationDateTime',
            label: 'firstRegistrationDateTime',
            inputProps: { type: 'date', max: new Date() },
            value: baseAsset?.firstRegistrationDateTime || '',
        },
    ]
}

export function UsageFields(
    baseAsset: GetBaseAssetDetail
): IFormField<GetBaseAssetDetail>[] {
    return [
        {
            slug: 'usageType',
            label: 'usageType',
            inputProps: {
                type: 'select',
            },
            optionsKeysValues: USAGE_TYPE_PAIRS,
            value: baseAsset?.usageType || '',
        },
        {
            slug: 'usagePriceCalculationMethod',
            label: 'usagePriceCalculationMethod',
            inputProps: {
                type: 'select',
            },
            optionsKeysValues: CALCULATION_TYPE_PAIRS,
            value: baseAsset?.usagePriceCalculationMethod || 0,
        },
        {
            slug: 'usageLimit',
            label: 'usageLimit',
            inputProps: {
                type: 'number',
            },
            value: baseAsset?.usageLimit || '',
        },
        {
            slug: 'pricePerUnitUnderUsageLimit',
            label: 'usagePrice',
            inputProps: {
                type: 'number',
            },
            value: baseAsset?.pricePerUnitUnderUsageLimit || '',
        },
        {
            slug: 'pricePerUnitOverUsageLimit',
            label: 'usagePriceOverLimitFull',
            inputProps: {
                type: 'number',
            },
            value: baseAsset?.pricePerUnitOverUsageLimit || '',
        },
    ]
}
