import { ReactElement } from 'react'

import { Box, Fade, Flex, useColorModeValue } from '@chakra-ui/react'

import { useContractService } from '../../../../services/contract/Contract.services'
import KeyInformationFeature from '../keyInformation/KeyInformation.component'
import KeyInformationContractFeature from '../keyInformation/KeyInformationContract.component'

export default function ContractDetailHeader(): ReactElement {
    const { isLoading } = useContractService()
    return (
        <Box w="100%" bg={useColorModeValue('white', 'secondary.850')}>
            {!isLoading && (
                <Fade in={!isLoading}>
                    <Box
                        display={['none', 'none', 'none', 'none', 'flex']}
                        justifyContent={'space-between'}
                    >
                        <Flex alignItems={'center'} gap={4}>
                            <KeyInformationContractFeature />
                        </Flex>
                        <Flex alignItems={'center'} gap={4}>
                            <KeyInformationFeature />
                        </Flex>
                    </Box>
                    <Box
                        display={['flex', 'flex', 'flex', 'flex', 'none']}
                        flexWrap={'nowrap'}
                        overflowX={'auto'}
                        alignItems={'center'}
                        gap={4}
                        sx={{
                            '::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}
                    >
                        <KeyInformationContractFeature />
                        <KeyInformationFeature />
                    </Box>
                </Fade>
            )}
        </Box>
    )
}
