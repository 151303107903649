import { ReactElement } from 'react'

import { Box } from '@chakra-ui/react'

import DynamicForm from '../../../../../../features/dynamicForm/DynamicForm.feature'
import withModalHOC from '../../../../../../hoc/modal.hoc'
import { ProductDTO, ProductRuleDTO } from '../../../../../../utils/types/types'
import { editContractTemplateRuleFormFields } from './ContractTemplateRules.config'

function ContractTemplateRuleContainer(props: any): ReactElement {
    const rule: Partial<ProductRuleDTO> = props.activeRule ?? {}

    return (
        <Box mt={4}>
            <DynamicForm<Partial<ProductRuleDTO>>
                key={rule.ruleType}
                data={rule}
                formFields={editContractTemplateRuleFormFields(rule)}
                onSubmit={(data: ProductDTO): void => {
                    props.handleSaveProductRule(data)
                    props.onClose()
                }}
                submitText={'confirm'}
                submitButtonProps={{ float: 'right', mt: 2 }}
            />
        </Box>
    )
}

const ContractTemplateRuleEdit = withModalHOC(ContractTemplateRuleContainer)

export default ContractTemplateRuleEdit
