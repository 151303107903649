/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import { AxiosError, AxiosInstance } from 'axios'

import { IApiResponse } from '../../interfaces/interfaces'
import { API_INSTANCE } from './index'

// TODO: API will become a service instead of this.

const equalOrHigher400Lower500 = (code: number) => code >= 400 && code < 500

const getGeneralAPIErrorMessage = (err: AxiosError | any): string => {
    let message = 'API call failed'
    if (
        equalOrHigher400Lower500(err?.response?.status) ||
        equalOrHigher400Lower500(err?.response?.Status)
    ) {
        message = err?.response?.data.detail
    } else if (err?.response?.data?.Title) {
        message = err?.response?.data?.Title
    }
    if (
        (equalOrHigher400Lower500(err?.response?.status) ||
            equalOrHigher400Lower500(err?.response?.Status)) &&
        err?.response?.data?.Title
    ) {
        message = err?.response?.data?.Title
    }

    if (
        err?.response?.status === 400 &&
        err?.response?.data &&
        err?.response?.data?.errors
    ) {
        message = err?.response?.data?.title
    }
    return String(message)
}

export async function generalGetAPI(
    endpoint: string,
    config?: any,
    instance?: AxiosInstance
): Promise<IApiResponse> {
    const apiInstance = instance || API_INSTANCE
    try {
        const response: any = await apiInstance.get(endpoint, {
            // cache: {
            //     ttl: endpoint.includes('self') ? 1000 * 60 * 30 : 1000 * 60 * 5,
            // },
            ...config,
        })

        const apiResponse: IApiResponse = {
            message: 'Data successfully retrieved',
            isOk: true,
            data: response.data,
        }
        return apiResponse
    } catch (err: any) {
        const apiResponseError: IApiResponse = {
            message: getGeneralAPIErrorMessage(err),
            isOk: false,
            data: null,
        }
        return apiResponseError
    }
}
// Whe should have specific Request Methods to type responses
export async function generalPostAPI(
    endpoint: string,
    data?: any,
    config?: any
): Promise<IApiResponse> {
    try {
        const response: any = await API_INSTANCE.post(
            endpoint,
            {
                ...data,
            },
            config
        )
        const apiResponse: IApiResponse = {
            message: 'Data successfully send',
            isOk: true,
            data: response.data,
        }
        // API_INSTANCE.storage.remove(`get: ${endpoint}`)
        return apiResponse
    } catch (err: any) {
        const msgArr = err.message.split(' ')
        const apiResponseError: IApiResponse = {
            message: getGeneralAPIErrorMessage(err),
            isOk: false,
            data: null,
            status: parseInt(msgArr[msgArr.length - 1] ?? 500),
            err,
        }
        return apiResponseError
    }
}

export async function generalPostAPIBlobResponse(
    endpoint: string,
    data?: any
): Promise<IApiResponse> {
    try {
        const response: any = await API_INSTANCE.post(
            endpoint,
            { ...data },
            { responseType: 'blob' }
        )
        const apiResponse: IApiResponse = {
            message: 'Data successfully send',
            isOk: true,
            data: response.data,
        }
        return apiResponse
    } catch (err: any) {
        const msgArr = err.message.split(' ')
        const apiResponseError: IApiResponse = {
            message: getGeneralAPIErrorMessage(err),
            isOk: false,
            data: null,
            status: parseInt(msgArr[msgArr.length - 1] ?? 500),
            err,
        }
        return apiResponseError
    }
}

export async function generalFileGETAPI(endpoint: string) {
    try {
        const response: any = await API_INSTANCE({
            method: 'GET',
            url: process.env.REACT_APP_API + endpoint,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            responseType: 'arraybuffer',
            // dataType: 'blob',
        })
        const apiResponse: IApiResponse = {
            message: 'Data successfully retrieved',
            isOk: true,
            data: response.data,
        }
        return apiResponse
    } catch (err: any) {
        const apiResponseError: IApiResponse = {
            message: getGeneralAPIErrorMessage(err),
            isOk: false,
            data: null,
        }
        return apiResponseError
    }
}

export async function generalFilePostAPI(endpoint: string, FormData: FormData) {
    try {
        const response: any = await API_INSTANCE({
            method: 'post',
            url: process.env.REACT_APP_API + endpoint,
            data: FormData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        const apiResponse: IApiResponse = {
            message: 'File Successfully send',
            isOk: true,
            data: response.data,
        }
        return apiResponse
    } catch (err: any) {
        const apiResponseError: IApiResponse = {
            message: getGeneralAPIErrorMessage(err),
            isOk: false,
            data: null,
        }
        return apiResponseError
    }
}

export async function generalPutAPI(endpoint: string, data: any, config?: any) {
    try {
        const response: any = await API_INSTANCE.put(
            endpoint,
            { ...data },
            config
        )

        const apiResponse: IApiResponse = {
            message: 'Data successfully send',
            isOk: true,
            data: response.data,
        }
        return apiResponse
    } catch (err: any) {
        const apiResponseError: IApiResponse = {
            message: getGeneralAPIErrorMessage(err),
            isOk: false,
            data: null,
        }
        return apiResponseError
    }
}

export async function generalFilePutAPI(endpoint: string, FormData: FormData) {
    try {
        const response: any = await API_INSTANCE({
            method: 'put',
            url: process.env.REACT_APP_API + endpoint,
            data: FormData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        const apiResponse: IApiResponse = {
            message: 'File Successfully send',
            isOk: true,
            data: response.data,
        }
        return apiResponse
    } catch (err: any) {
        const apiResponseError: IApiResponse = {
            message: getGeneralAPIErrorMessage(err),
            isOk: false,
            data: null,
        }
        return apiResponseError
    }
}

export async function generalPatchAPI(endpoint: string, data?: any) {
    try {
        const response: any = await API_INSTANCE.patch(endpoint, { ...data })

        const apiResponse: IApiResponse = {
            message: 'Data successfully send',
            isOk: true,
            data: response.data,
        }
        return apiResponse
    } catch (err: any) {
        const apiResponseError: IApiResponse = {
            message: getGeneralAPIErrorMessage(err),
            isOk: false,
            data: null,
        }
        return apiResponseError
    }
}

export async function generalDeleteAPI(endpoint: string, data?: any) {
    try {
        const response: any = await API_INSTANCE.delete(endpoint, { ...data })

        const apiResponse: IApiResponse = {
            message: 'Data successfully send',
            isOk: true,
            data: response.data,
        }
        return apiResponse
    } catch (err: any) {
        const apiResponseError: IApiResponse = {
            message: getGeneralAPIErrorMessage(err),
            isOk: false,
            data: null,
        }
        return apiResponseError
    }
}

export async function generalPaginatedGetAPI(
    getEndpoint: Function,
    config: any
) {
    let currentPage = 1
    let totalPages = 1
    let allItems: any[] = []

    try {
        while (currentPage <= totalPages) {
            const response = await generalGetAPI(
                getEndpoint(currentPage),
                config
            )

            if (response?.data?.items?.length) {
                const {
                    items,
                    currentPage: currentPageNum,
                    totalPages: totalPagesNum,
                } = response.data

                allItems = [...allItems, ...items]
                currentPage = currentPageNum
                totalPages = totalPagesNum
            } else {
                console.error('Error fetching items from API')
                return null
            }

            currentPage++
        }

        return allItems
    } catch (error) {
        console.error('Error fetching items from API:', error)
        return null
    }
}
