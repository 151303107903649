import findIndex from 'lodash/findIndex'
import isEqual from 'lodash/isEqual'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

import { useBoolean } from '@chakra-ui/react'

import { useNumberFormatterContext } from '../../../../../../services/contexts/NumberFormatter.context'
import {
    ProductDTO,
    ProductRuleDTO,
    ProductRuleType,
} from '../../../../../../utils/types/types'
import DynamicGrid from '../../../../../demo/DynamicAGGrid.component'
import ContractTemplateRuleEdit from './ContractTemplateRuleEdit.component'
import { columnsConfig } from './ContractTemplateRules.config'

interface IEditProductRules {
    product: ProductDTO
    setProduct: Function
    handleUpdateProduct: Function
    enableSave: boolean
    setEnableSave: Function
}

export default function EditProductRules({
    product,
    setProduct,
    handleUpdateProduct,
    enableSave,
    setEnableSave,
}: IEditProductRules): ReactElement {
    const translate = useTranslation().t
    const [isAddProductRuleOpen, setAddProductRule] = useBoolean()
    const [isEditProductRuleOpen, setEditProductRule] = useBoolean()

    const [activeRule, setActiveRule] = useState<ProductRuleDTO | null>(null)
    const [productRules, setProductRules] = useState<ProductRuleDTO[]>(
        product?.productRules ?? []
    )
    const [tableKey, setTableKey] = useState(v4())
    const { formatValue } = useNumberFormatterContext()

    useEffect(() => {
        setTableKey(v4())
    }, [product, productRules])

    useEffect(() => {
        if (enableSave) {
            handleUpdateProduct({
                ...product,
                productRules,
            })
            setEnableSave(false)
        }
    })

    const onEdit = (data: ProductRuleDTO): void => {
        setActiveRule(data)
        setEditProductRule.on()
    }

    const handleSaveProductRule = (data: ProductRuleDTO): void => {
        if (
            data.ruleType === ProductRuleType.IncludeCreationFee ||
            data.ruleType === ProductRuleType.IsVariableInterest
        ) {
            data.defaultValue = data.defaultValue ? 'true' : 'false'
        } else {
            data.defaultValue = `${data.defaultValue}`
        }
        if (activeRule) {
            const index = findIndex(productRules, (o) => isEqual(o, activeRule))
            if (typeof index === 'number') {
                productRules[index] = data
            }
            setProductRules([...productRules])
            setProduct({ ...product, productRules })
        } else {
            setProductRules((prevArray: any) => [...prevArray, data])
            setProduct({
                ...product,
                productRules: [...(product.productRules ?? []), data],
            })
        }
        setAddProductRule.off()
        setActiveRule(null)
    }

    return (
        <>
            <DynamicGrid
                tableId="contractTemplateRulesTable"
                key={tableKey}
                columns={columnsConfig(translate, onEdit, formatValue)}
                rowData={productRules}
                pagination={true}
                rowMultiSelectWithClick={false}
            />
            <ContractTemplateRuleEdit
                isCentered={true}
                activeRule={activeRule}
                isModalOpen={isAddProductRuleOpen || isEditProductRuleOpen}
                onClose={(): void => {
                    setAddProductRule.off()
                    setEditProductRule.off()
                }}
                modalTitle={translate(
                    isEditProductRuleOpen ? 'editProductRule' : 'addProductRule'
                )}
                handleSaveProductRule={handleSaveProductRule}
                maxWidth={'620px'}
            />
        </>
    )
}
