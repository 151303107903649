import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
    Button,
    Flex,
    IconButton,
    Stack,
    Tooltip,
    useToast,
} from '@chakra-ui/react'

import PageContainer from '../../../components/pageContainer/PageContainer.component'
import InsuranceContainer from '../../../features/insurance/Insurance.feature'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import {
    generalDeleteAPI,
    generalGetAPI,
    generalPostAPI,
    generalPutAPI,
} from '../../../services/API/general.api'
import useIsMounted from '../../../services/hooks/isMounted'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
} from '../../../utils/functions.utils'
import { BaseInsuranceDto } from '../../../utils/types/types'
import DynamicGrid from '../../demo/DynamicAGGrid.component'
import {
    DEFAULT_INSURANCE_ITEM,
    getInsuranceRows,
    insuranceHeaders,
} from './Insurance.config'
import {
    useVAT,
    VATContextProvider,
} from '../../../services/contexts/vat.context'

type OptionalBaseInsuranceDTO = Partial<BaseInsuranceDto>

function InsuranceSetup(): ReactElement {
    const gridApiRef = useRef(null)
    const [insurances, setInsurances] = useState<OptionalBaseInsuranceDTO[]>()
    const [selectedInsurance, setSelectedInsurance] =
        useState<OptionalBaseInsuranceDTO>()
    const [isInsuranceModalOpen, setInsuranceModalOpen] =
        useState<boolean>(false)
    const translate = useTranslation().t
    const isMounted = useIsMounted()
    const toast = useToast()
    const { VAT } = useVAT()

    const getInsurancesHandler = async (): Promise<void> => {
        const response = await generalGetAPI(API_ENDPOINTS.insurance)
        if (!response.isOk) {
            toast(baseErrorToastOptions(response.message))
            return
        }
        setInsurances(response.data)
    }

    const handleInsuranceSave = async (
        serviceItem: OptionalBaseInsuranceDTO
    ): Promise<void | string | number> => {
        setInsuranceModalOpen(false)
        let response
        const payload = {
            ...serviceItem,
        }
        if (!serviceItem?.baseInsuranceNumber) {
            response = await generalPostAPI(API_ENDPOINTS.insurance, payload)
            if (response.isOk) {
                toast(baseSuccessToastOptions(translate('insuranceAdded')))
            }
        } else {
            response = await generalPutAPI(
                `${API_ENDPOINTS.insurance}/${serviceItem?.baseInsuranceNumber}`,
                payload
            )
            if (response.isOk) {
                toast(baseSuccessToastOptions(translate('insuranceUpdated')))
            }
        }
        if (!response.isOk) {
            return toast(baseErrorToastOptions(response.message))
        }
        getInsurancesHandler()
        setSelectedInsurance({})
    }

    const onGridReady = (params: any): void => {
        gridApiRef.current = params.api
    }

    const handleDeleteInsuranceItem = async (
        item: OptionalBaseInsuranceDTO
    ) => {
        const response = await generalDeleteAPI(
            `${API_ENDPOINTS.insurance}/${item.baseInsuranceNumber}`
        )
        if (response.isOk) {
            toast(baseSuccessToastOptions(translate('baseInsuranceDeleted')))
            getInsurancesHandler()
        } else {
            toast(baseErrorToastOptions(response.message))
        }
    }

    useEffect(() => {
        if (isMounted()) {
            getInsurancesHandler()
        }
    }, [isMounted])

    return (
        <PageContainer>
            <Stack spacing={4}>
                <Flex
                    alignItems={'center'}
                    justifyContent="flex-end"
                    gap={4}
                    flexWrap="wrap"
                >
                    <Button
                        onClick={(): void => {
                            setSelectedInsurance({ ...DEFAULT_INSURANCE_ITEM })
                            setInsuranceModalOpen(true)
                        }}
                    >
                        {translate('addInsurance')}
                    </Button>
                </Flex>

                <DynamicGrid
                    tableId="insurancesSetupPageTable"
                    onGridReady={onGridReady}
                    columns={insuranceHeaders(
                        false,
                        translate,
                        (params: any): any => (
                            <>
                                <Tooltip
                                    label={translate('edit')}
                                    placement="top"
                                    hasArrow
                                >
                                    <IconButton
                                        mr={2}
                                        aria-label="Edit"
                                        size="sm"
                                        variant={'outline'}
                                        onClick={(): any => {
                                            console.log('selected', params)
                                            setSelectedInsurance({
                                                ...params?.data,
                                            })
                                            setInsuranceModalOpen(true)
                                        }}
                                        icon={<EditIcon />}
                                    />
                                </Tooltip>
                                <Tooltip
                                    label={translate('delete')}
                                    placement="top"
                                    hasArrow
                                >
                                    <IconButton
                                        mr={2}
                                        aria-label="delete"
                                        size="sm"
                                        variant={'outline'}
                                        onClick={() =>
                                            handleDeleteInsuranceItem(
                                                params?.data
                                            )
                                        }
                                        icon={<DeleteIcon />}
                                    />
                                </Tooltip>
                            </>
                        ),
                        VAT
                    )}
                    rowData={getInsuranceRows(insurances)}
                    pagination={true}
                    rowMultiSelectWithClick={true}
                    rowSelection={'multiple'}
                />

                {/* Modals */}
                {isInsuranceModalOpen && selectedInsurance && (
                    <InsuranceContainer
                        isModalOpen={isInsuranceModalOpen}
                        onClose={() => {
                            setInsuranceModalOpen(false)
                        }}
                        modalTitle={
                            selectedInsurance?.baseInsuranceNumber
                                ? translate('editInsurance')
                                : translate('addInsurance')
                        }
                        handleInsuranceSave={handleInsuranceSave}
                        value={selectedInsurance}
                    />
                )}
            </Stack>
        </PageContainer>
    )
}

function InsuranceSetupPage(): ReactElement {
    return (
        <VATContextProvider>
            <InsuranceSetup></InsuranceSetup>
        </VATContextProvider>
    )
}

export default InsuranceSetupPage
