import camelCase from 'lodash/camelCase'
/* eslint-disable no-unused-vars */
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'

import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../../services/API/general.api'
import { useNumberFormatterContext } from '../../../../services/contexts/NumberFormatter.context'
import { useContractService } from '../../../../services/contract/Contract.services'
import { getSpecificKeyFromObject } from '../../../../utils/functions.utils'
import { formatDate } from '../../../../utils/localization/culture.utils'
import {
    ContractStatus,
    UsageDTO,
    UsageStatus,
    UsageType,
} from '../../../../utils/types/types'
import DynamicGrid from '../../../demo/DynamicAGGrid.component'
import UsagePopupComponent from './UsagePopup.component'

export default function ContractUsageTab(): ReactElement {
    console.log('ContractUsageTab')
    const { contract } = useContractService()

    const [usageData, setUsageData] = useState<UsageDTO[]>([])
    const [isAddUsageOpen, setAddUsageOpen] = useState<boolean>(false)

    const translate = useTranslation().t
    const { formatValue } = useNumberFormatterContext()
    const getAllUsageForContractAssets = async (): Promise<void> => {
        const response = await generalGetAPI(
            `${API_ENDPOINTS.usage}?contractNumber=${contract.contractNumber}`
        )

        if (response.isOk) {
            setUsageData(response.data.length > 0 ? response.data : [])
        }
    }

    const handleOpenUsagePopup = (): void => {
        if (isAddUsageOpen) return
        setAddUsageOpen(true)
    }
    const handleSubmitFinish = (): void => {
        setAddUsageOpen(false)
        getAllUsageForContractAssets()
    }

    useEffect(() => {
        getAllUsageForContractAssets()
    }, [JSON.stringify(contract)])

    const CONTRACT_USAGE_HEADERS = [
        { headerName: translate('assetNumber'), field: 'assetNumber' },
        {
            headerName: translate('usageValue'),
            field: 'value',
            valueFormatter: (params: any) => {
                formatValue(params.data.usageValue)
            },
        },
        {
            headerName: translate('invoiced'),
            field: 'invoiced',
            valueGetter: (params: any) =>
                translate(
                    camelCase(
                        getSpecificKeyFromObject<typeof UsageStatus>(
                            UsageStatus,
                            params.data.status
                        )
                    )
                ),
        },
        {
            headerName: translate('usageType'),
            field: 'usageType',
            valueGetter: (params: any) =>
                translate(
                    camelCase(
                        getSpecificKeyFromObject<typeof UsageType>(
                            UsageType,
                            params.data.usageType
                        )
                    )
                ),
        },
        {
            headerName: translate('periodStart'),
            field: 'periodStart',
            valueGetter: (params: any) => formatDate(params.data.periodStart),
        },
        {
            headerName: translate('periodEnd'),
            field: 'periodEnd',
            valueGetter: (params: any) => formatDate(params.data.periodEnd),
        },
    ]

    return (
        <Box>
            <Box mt={8}>
                <DynamicGrid
                    tableId="contractUsageTable"
                    columns={CONTRACT_USAGE_HEADERS}
                    rowData={usageData}
                    headers={
                        <Button
                            textAlign={'right'}
                            marginBottom={4}
                            isDisabled={
                                contract.status !== ContractStatus.Active
                            }
                            onClick={handleOpenUsagePopup}
                        >
                            {translate('addUsage')}
                        </Button>
                    }
                />
            </Box>

            {/* Modals */}
            <UsagePopupComponent
                isModalOpen={isAddUsageOpen}
                onClose={() => {
                    setAddUsageOpen(false)
                }}
                modalTitle={'Add Usage'}
                assets={contract.contractAssets}
                onSubmitFinish={handleSubmitFinish}
            />
        </Box>
    )
}
