/* eslint-disable @typescript-eslint/no-shadow */
import { formFieldValiationRules } from '../../services/rules/ProductRulesEnforcer.services'
import {
    isEmpty,
    validationStringLengthBiggerThan,
} from '../../utils/functions.utils'
import {
    ContractAssetDTO,
    ProductRuleDTO,
    ProductRuleType,
} from '../../utils/types/types'

export interface IAddAssetCustomErrors {
    make: string[]
    model: string[]
    purchaseValue: string[]
    downpayment: string[]
    restValue: string[]
    downpaymentPercentage: string[]
    restValuePercentage: string[]
    usageType: string[]
    usagePriceCalculationMethod: string[]
    pricePerUnitUnderUsageLimit: string[]
    pricePerUnitOverUsageLimit: string[]
    assetTypeData: string[]
    assetType: string[]
}

interface ValidateAssetResponse {
    hasError: boolean
    errorMessages: IAddAssetCustomErrors
}

function checkForMinimumOrMaximumRule(
    ruleType: ProductRuleType,
    modifier: 'minimumValue' | 'maximumValue',
    productRules: ProductRuleDTO[],
    value: number,
    errorMessage: string,
    parentValue?: number
): string | undefined {
    const notValid = formFieldValiationRules(
        ruleType,
        productRules,
        modifier,
        value as number,
        parentValue
    )
    return notValid ? errorMessage : undefined
}

export function validateAddAssetForm(
    contractAsset: ContractAssetDTO,
    productRules: ProductRuleDTO[],
    isBaseAsset: boolean,
    translate: (key: string) => string
): ValidateAssetResponse {
    let rule: ProductRuleDTO | undefined
    const errorMessages: IAddAssetCustomErrors = {
        make: [],
        model: [],
        purchaseValue: [],
        downpayment: [],
        restValue: [],
        downpaymentPercentage: [],
        restValuePercentage: [],
        usageType: [],
        usagePriceCalculationMethod: [],
        pricePerUnitUnderUsageLimit: [],
        pricePerUnitOverUsageLimit: [],
        assetTypeData: [],
        assetType: [],
    }

    const isPartRegistrationValidation = (
        contractAsset: ContractAssetDTO
    ): boolean => {
        const isValid =
            contractAsset.purchaseValue +
                contractAsset.deliveryCost +
                (contractAsset?.registrationValue ?? 0) >=
            contractAsset.restValue + (contractAsset?.downpayment ?? 0)
        return isValid
    }

    Object.entries(contractAsset).forEach(([key, value]) => {
        // eslint-disable-next-line default-case
        switch (key) {
            case 'make':
                if (isEmpty(value)) {
                    errorMessages[key].push(translate('makeRequiredRule'))
                }
                if (validationStringLengthBiggerThan(value, 100)) {
                    errorMessages[key].push(translate('makeStringLengthRule'))
                }
                break
            case 'model':
                if (isEmpty(value)) {
                    errorMessages[key].push(translate('modelRequiredRule'))
                }
                if (validationStringLengthBiggerThan(value, 100)) {
                    errorMessages[key].push(translate('modelStringLengthRule'))
                }
                break
            case 'purchaseValue':
                if (isBaseAsset) break
                rule = productRules?.find(
                    (item) => item.ruleType === ProductRuleType.Price
                )
                if (!value && contractAsset.assetTypeData) {
                    errorMessages[key].push('Purchase value is required')
                }
                if (value) {
                    if (
                        !contractAsset.isPartRegistration &&
                        !isEmpty(contractAsset.downpayment) &&
                        !isEmpty(contractAsset.restValue) &&
                        value -
                            (contractAsset.downpayment as number) -
                            contractAsset.restValue <
                            0
                    ) {
                        errorMessages[key].push(translate('priceSuperiority'))
                    }

                    if (rule) {
                        const errorMinimum = checkForMinimumOrMaximumRule(
                            ProductRuleType.Price,
                            'minimumValue',
                            productRules,
                            value as number,
                            `${translate('valueBelowMinimum')} ${
                                rule.minimumValue
                            }`
                        )

                        const errorMaximum = checkForMinimumOrMaximumRule(
                            ProductRuleType.Price,
                            'maximumValue',
                            productRules,
                            value as number,
                            `${translate('valueAboveMaximum')} ${
                                rule.maximumValue
                            }`
                        )
                        errorMinimum && errorMessages[key].push(errorMinimum)
                        errorMaximum && errorMessages[key].push(errorMaximum)
                    }
                }
                break
            case 'restValue':
                if (!isEmpty(value)) {
                    if (contractAsset.purchaseValue < value) {
                        errorMessages[key].push(
                            translate('restValueToPriceRelationship')
                        )
                    }
                }
                break
            case 'downpayment':
                if (!isEmpty(value)) {
                    rule = productRules?.find(
                        (item) => item.ruleType === ProductRuleType.Downpayment
                    )
                    if (contractAsset.purchaseValue) {
                        if (rule) {
                            const errorMinimum = checkForMinimumOrMaximumRule(
                                ProductRuleType.Downpayment,
                                'minimumValue',
                                productRules,
                                value as number,
                                `${translate('valueBelowMinimum')} ${
                                    rule?.targeted
                                        ? contractAsset.purchaseValue *
                                          ((rule.minimumValue as number) / 100)
                                        : rule.minimumValue
                                }`,
                                contractAsset.purchaseValue
                            )

                            const errorMaximum = checkForMinimumOrMaximumRule(
                                ProductRuleType.Downpayment,
                                'maximumValue',
                                productRules,
                                value as number,
                                `${translate('valueAboveMaximum')} ${
                                    rule?.targeted
                                        ? contractAsset.purchaseValue *
                                          ((rule.maximumValue as number) / 100)
                                        : rule.maximumValue
                                }`,
                                contractAsset.purchaseValue
                            )
                            errorMinimum &&
                                errorMessages[key].push(errorMinimum)
                            errorMaximum &&
                                errorMessages[key].push(errorMaximum)
                        }

                        if (value > contractAsset.purchaseValue) {
                            errorMessages.downpayment.push(
                                `Downpayment value cannot be greater than purchase value`
                            )
                        }
                        if (
                            !isEmpty(contractAsset.restValue) &&
                            !contractAsset.isPartRegistration &&
                            value + contractAsset.restValue >
                                contractAsset.purchaseValue
                        ) {
                            errorMessages.restValue.push(
                                'The sum of downpayment and residual value cannot be higher than the purchase value'
                            )
                            errorMessages.downpayment.push(
                                'The sum of downpayment and residual value cannot be higher than the purchase value'
                            )
                        }
                        if (
                            contractAsset.isPartRegistration &&
                            !isPartRegistrationValidation(contractAsset)
                        ) {
                            errorMessages.purchaseValue.push(
                                translate('isPartRegistrationValidationFailure')
                            )
                            errorMessages.restValue.push(
                                translate('isPartRegistrationValidationFailure')
                            )
                            errorMessages.downpayment.push(
                                translate('isPartRegistrationValidationFailure')
                            )
                        }
                    }
                }
                break
            case 'pricePerUnitUnderUsageLimit':
                rule = productRules?.find(
                    (item) => item.ruleType === ProductRuleType.UsagePrice
                )
                if (!isEmpty(value) && rule) {
                    const errorMinimum = checkForMinimumOrMaximumRule(
                        ProductRuleType.UsagePrice,
                        'minimumValue',
                        productRules,
                        value as number,
                        `${translate('valueBelowMinimum')} ${rule.minimumValue}`
                    )

                    const errorMaximum = checkForMinimumOrMaximumRule(
                        ProductRuleType.UsagePrice,
                        'maximumValue',
                        productRules,
                        value as number,
                        `${translate('valueAboveMaximum')} ${rule.maximumValue}`
                    )
                    errorMinimum && errorMessages[key].push(errorMinimum)
                    errorMaximum && errorMessages[key].push(errorMaximum)
                }
                break

            case 'pricePerUnitOverUsageLimit':
                rule = productRules?.find(
                    (item) => item.ruleType === ProductRuleType.UsagePrice
                )
                if (!isEmpty(value) && rule) {
                    const errorMinimum = checkForMinimumOrMaximumRule(
                        ProductRuleType.UsagePriceOverLimit,
                        'minimumValue',
                        productRules,
                        value as number,
                        `${translate('valueBelowMinimum')} ${rule.minimumValue}`
                    )

                    const errorMaximum = checkForMinimumOrMaximumRule(
                        ProductRuleType.UsagePriceOverLimit,
                        'maximumValue',
                        productRules,
                        value as number,
                        `${translate('valueAboveMaximum')} ${rule.maximumValue}`
                    )
                    errorMinimum && errorMessages[key].push(errorMinimum)
                    errorMaximum && errorMessages[key].push(errorMaximum)
                }
                break
            case 'assetType':
                if (isEmpty(value)) {
                    errorMessages[key].push(translate('assetTypeRequired'))
                }
                break
        }
    })

    return {
        hasError: Object.values(errorMessages).some((item) => item.length > 0),
        errorMessages,
    }
}
