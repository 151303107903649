import {
    MutableRefObject,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Stack } from '@chakra-ui/react'

import TableSkeleton from '../../../../../components/skeleton/table/table.skeleton.component'
import ErrorBoundaryWrapper from '../../../../../features/errorBoundary/ErrorBoundary.feature'
import { useBaseServicesContext } from '../../../../../services/contexts/BaseServices.context'
import { useLoading } from '../../../../../services/contexts/Loading.context'
import { useContractService } from '../../../../../services/contract/Contract.services'
import { CustomServiceType } from '../../../../../services/contract/Contract.services.config'
import {
    AssetServiceDTO,
    ContractAssetDTO,
} from '../../../../../utils/types/types'
import DynamicGrid from '../../../../demo/DynamicAGGrid.component'
import { updateContractAssetService } from './ContractAssetServices.api'

interface IActivateAsset {
    asset: ContractAssetDTO
    onAdd: Function
    setSelectedAsset: Function
    productId?: number
}

export default function AddContractAssetService({
    asset,
    onAdd,
    setSelectedAsset,
    productId,
}: IActivateAsset): ReactElement {
    const gridApiRef = useRef(null)

    const selectionActions: MutableRefObject<any> = useRef(null)
    const translate = useTranslation().t
    const { globalLoading, stopGlobalLoading } = useLoading()
    const { contract, getContract } = useContractService()
    const {
        baseServices,
        getServicesHandler,
        isLoading: isLoadingBaseServices,
    } = useBaseServicesContext()
    const [selectedServices, setSelectedServices] = useState<
        CustomServiceType[]
    >([])

    const addServices = async (): Promise<void> => {
        const existingServices: AssetServiceDTO[] =
            (asset.assetServices as AssetServiceDTO[]) || []

        selectedServices.forEach((item) => {
            const standardPrice = item.baseServiceValues?.find(
                (baseItemValues) => baseItemValues.isCurrent
            )?.value
            existingServices.push({
                ...item,
                fixedValueOverride: standardPrice,
            })
        })

        if (Object.keys(contract).length > 0 && asset?.assetNumber) {
            const loadingID = globalLoading()
            await updateContractAssetService(
                asset.assetNumber,
                existingServices,
                () => getContract()
            )
            stopGlobalLoading(loadingID)
        } else {
            setSelectedAsset({ ...asset, assetServices: [...existingServices] })
        }

        selectionActions.current?.resetSelectedItems()
        onAdd()
    }
    const columns = [
        {
            field: 'baseServiceNumber',
            headerName: translate('baseServiceNumber'),
            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
        },
        {
            field: 'description',
            headerName: translate('description'),
        },
    ]

    const onGridReady = (params: any): void => {
        gridApiRef.current = params.api
    }

    const getSelectedData = (): void => {
        const selectedNodes = (gridApiRef?.current as any)?.getSelectedNodes()
        const selectedData = selectedNodes.map((node: any) => node.data)
        setSelectedServices(selectedData)
    }
    useEffect(() => {
        productId !== undefined && getServicesHandler(productId)
    }, [productId])

    return (
        <ErrorBoundaryWrapper id="add-contract-asset-service-error">
            <Stack spacing={8}>
                {isLoadingBaseServices ? (
                    <TableSkeleton />
                ) : (
                    <DynamicGrid
                        tableId="addContractAssetServiceTable"
                        onGridReady={onGridReady}
                        columns={columns}
                        rowData={baseServices}
                        pagination={true}
                        rowMultiSelectWithClick={false}
                        onSelectionChanged={getSelectedData}
                    ></DynamicGrid>
                )}
                <Button
                    mr={3}
                    isDisabled={
                        selectedServices.length === 0 || contract.isLocked
                    }
                    onClick={addServices}
                >
                    {translate('addService')}
                </Button>
            </Stack>
        </ErrorBoundaryWrapper>
    )
}
