import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    NumberInput,
    NumberInputField,
    Select,
    Spinner,
    Text,
    useToast,
} from '@chakra-ui/react'

import ProlongationTable from '../../../../../features/genericTables/prolongationAssetsTable/ProlongationAssetsTable.component'
import { ContractProlongationAssetDTO } from '../../../../../features/genericTables/prolongationAssetsTable/ProlongationAssetsTable.config'
import withModalHOC, { ModalSharedProps } from '../../../../../hoc/modal.hoc'
import API_ENDPOINTS from '../../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
    generalPutAPI,
} from '../../../../../services/API/general.api'
import { useLoading } from '../../../../../services/contexts/Loading.context'
import { useProduct } from '../../../../../services/contexts/Product.context'
import { useContractService } from '../../../../../services/contract/Contract.services'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    standaloneToast,
} from '../../../../../utils/functions.utils'
import { formatNumber } from '../../../../../utils/localization/culture.utils'
import {
    ProductDTO,
    ProductType,
    ProlongationContractAssetState,
    ProlongationNumbersDTO,
    ProlongationOfferCommandBase,
} from '../../../../../utils/types/types'

function ProlongContractComponent(props: ModalSharedProps): ReactElement {
    const translate = useTranslation().t
    const toast = useToast()
    const { contract, getContract } = useContractService()
    const [productId, setProductId] = useState<number>(contract.productId ?? 0)
    const { products, isLoading } = useProduct()
    const [selectedProduct, setSelectedProduct] = useState<ProductDTO>()
    const [prolongationEstimate, setProlongationEstimate] =
        useState<ProlongationNumbersDTO>()
    const [leasingPeriod, setLeasingPeriod] = useState<number>(
        contract?.prolongationOffer?.leasingPeriod ||
            contract?.leasingPeriod ||
            0
    )
    const mappedProlongationAssets: ContractProlongationAssetDTO[] = (
        contract?.contractAssets || []
    ).map((asset) => {
        const prolongationAsset =
            (contract?.prolongationOffer?.prolongationContractAssets?.find(
                (a) => a.contractAssetNumber === asset.assetNumber
            ) || {
                contractAssetNumber: asset.assetNumber,
                targetRestValue: asset.restValue,
            }) as ProlongationContractAssetState
        return { ...asset, ...prolongationAsset }
    })
    const [prolongationAssets, setProlongationAssets] = useState<
        ContractProlongationAssetDTO[]
    >(mappedProlongationAssets)
    const { globalLoading, stopGlobalLoading } = useLoading()
    const navigation = useNavigate()

    useEffect(() => {
        products && setSelectedProduct(products.find((p) => p.id === productId))
        if (contract.prolongationOffer && !prolongationEstimate) {
            handleEstimateOffer()
        }
    }, [productId])

    const updateContractAsset = (
        targetRestValue: number,
        assetNumber: string
    ) => {
        const items = prolongationAssets.map(
            (asset: ContractProlongationAssetDTO) => {
                if (asset.contractAssetNumber === assetNumber) {
                    asset.targetRestValue = targetRestValue
                }
                return asset
            }
        )
        setProlongationAssets([...items])
    }

    const getOfferPayload = (): Partial<ProlongationOfferCommandBase> => ({
        contractNumber: contract.contractNumber,
        targetProductId: selectedProduct?.id,
        leasingPeriod:
            selectedProduct?.productType === ProductType.Subscription
                ? undefined
                : leasingPeriod,
        prolongationContractAssets: prolongationAssets.map((obj) =>
            (({ targetRestValue, contractAssetNumber }) => ({
                targetRestValue,
                contractAssetNumber,
            }))(obj)
        ),
    })

    const handleProlongContract = async (): Promise<void> => {
        if (!contract || !productId) return

        const loadingID = globalLoading()

        if (!selectedProduct) return

        const prolongationOffer = getOfferPayload()

        props.onClose()

        const apiCaller = contract?.prolongationOffer
            ? generalPutAPI
            : generalPostAPI

        const request = await apiCaller(
            API_ENDPOINTS.contractProlongation(contract.contractNumber || ''),
            prolongationOffer
        )
        if (!request.isOk) {
            standaloneToast(baseErrorToastOptions(request.message))
            stopGlobalLoading(loadingID)
            return
        }

        getContract(contract.contractNumber)
        stopGlobalLoading(loadingID)
        toast(baseSuccessToastOptions(translate('contractProlongationSuccess')))
        navigation(`/contracts/${contract.contractNumber}`)
    }

    const handleEstimateOffer = async () => {
        if (!contract) return

        const loadingID = globalLoading()

        // TODO should post offer to obtain estimation
        const request = await generalGetAPI(
            API_ENDPOINTS.estimateProlongationOffer(
                contract.contractNumber || ''
            )
        )

        if (!request.isOk) {
            standaloneToast(baseErrorToastOptions(request.message))
            stopGlobalLoading(loadingID)
            return
        }

        stopGlobalLoading(loadingID)
        toast(
            baseSuccessToastOptions(
                translate('contractProlongationEstimateSuccess')
            )
        )
        setProlongationEstimate(request.data as ProlongationNumbersDTO)
    }

    return (
        <Box>
            <FormControl mb={3}>
                <FormLabel>{translate('selectProduct')}</FormLabel>
                {isLoading ? (
                    <Spinner size={'md'} />
                ) : (
                    <Select
                        onChange={(
                            event: ChangeEvent<HTMLSelectElement>
                        ): void => {
                            const selectedProductId = parseInt(
                                event.target.value
                            )
                            setProductId(selectedProductId)
                        }}
                        placeholder={translate('selectProduct')}
                    >
                        {products?.map((product) => (
                            <option key={product.id} value={product.id}>
                                {product!.name}
                            </option>
                        ))}
                    </Select>
                )}
            </FormControl>
            {selectedProduct?.productType !== ProductType.Subscription && (
                <FormControl>
                    <FormLabel>{translate('leasingPeriod')}</FormLabel>
                    <NumberInput
                        placeholder={`${translate(
                            'leasingPeriodPlaceholder'
                        )}...`}
                        onChange={(event): void => setLeasingPeriod(+event)}
                        defaultValue={leasingPeriod}
                    >
                        <NumberInputField />
                    </NumberInput>
                </FormControl>
            )}
            <ProlongationTable
                productType={selectedProduct?.productType}
                assets={prolongationAssets || []}
                onChangeAgreedRestValue={(
                    targetRestValue: number,
                    assetNumber: string
                ) => {
                    updateContractAsset(targetRestValue, assetNumber)
                }}
            />
            {prolongationEstimate && (
                <HStack mt={4}>
                    <Text fontWeight={'medium'}>
                        {translate('prolongationEstimate')}:
                    </Text>
                    <Text>
                        {formatNumber(
                            prolongationEstimate?.totalPeriodicPaymentWithAll ||
                                0
                        )}
                    </Text>
                </HStack>
            )}
            <HStack
                mt={4}
                w={'100%'}
                display={'flex'}
                justifyContent={'flex-end'}
            >
                <Button
                    isDisabled={!productId || isLoading}
                    onClick={handleProlongContract}
                    textTransform={'capitalize'}
                >
                    {translate('save')}
                </Button>
            </HStack>
        </Box>
    )
}

export default withModalHOC(ProlongContractComponent)
