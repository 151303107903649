import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Button,
    ListItem,
    SimpleGrid,
    UnorderedList,
} from '@chakra-ui/react'

import API_ENDPOINTS from '../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../services/API/general.api'
import { useUser } from '../../services/contexts/user.context'
import { getProductRuleByType, isEmpty } from '../../utils/functions.utils'
import getLocaleFormats, {
    LocalizationConfiguration,
} from '../../utils/localization/localization.utils'
import {
    AssetTypeBO,
    ContractAssetDTO,
    ModuleType,
    ProductRuleDTO,
    ProductRuleType,
    ProductType,
} from '../../utils/types/types'
import {
    IAddAssetCustomErrors,
    validateAddAssetForm,
} from './AssetForm.validation'
import AssetBaseForm from './components/AssetBase.form'
import AssetPartsForm from './components/AssetParts.form'
import AssetUsageForm from './components/AssetUsage.form'
import AssetVehicleForm from './components/AssetVehicle.form'
import { useModule } from '../../services/contexts/Module.context'
import LockInAssetFormFeature from './components/AssetLockIn.form'
import { useContractService } from '../../services/contract/Contract.services'

interface IAddAssetCustom {
    actionType: 'add' | 'edit' | 'view'
    defaultContractAsset: ContractAssetDTO
    handleSubmit: Function
    isBaseAsset: boolean
    isUsageEnabled?: boolean
    productRules: ProductRuleDTO[]
}

const calculatePercentage = (purchaseValue: number, value: number): number =>
    (value / purchaseValue) * 100

const calculateValue = (purchaseValue: number, percentage: number): number =>
    (purchaseValue * percentage) / 100

export default function AssetFormFeature({
    actionType = 'edit',
    defaultContractAsset,
    handleSubmit,
    isBaseAsset,
    isUsageEnabled = false,
    productRules,
}: IAddAssetCustom): ReactElement {
    const { user } = useUser()
    const [format, setFormat] = useState<Partial<LocalizationConfiguration>>({})
    const translate = useTranslation().t
    const { product } = useContractService()
    const [contractAsset, setContractAsset] = useState<ContractAssetDTO>()
    const [existingAssetTypes, setExistingAssetTypes] = useState<AssetTypeBO[]>(
        []
    )
    const hasLockIn = product.productType === ProductType.Subscription

    async function fetchAssetTypes(): Promise<void> {
        const result = await generalGetAPI(API_ENDPOINTS.assetTypes)
        if (result?.isOk) {
            setExistingAssetTypes(result.data)
        }
    }

    const [downpaymentPercentage, setDownpaymentPercentage] =
        useState<number>(0)
    const [restValuePercentage, setRestValuePercentage] = useState<number>(0)
    const [errors, setErrors] = useState<IAddAssetCustomErrors>()
    const [warnings, setWarnings] = useState<IAddAssetCustomErrors>()
    const handleInputChange = (value: any, field: string): void => {
        contractAsset &&
            setContractAsset((prev) => ({ ...prev, [field]: value } as any))
    }
    const { hasAccess } = useModule()

    // Price / Purchase Value
    const purchaseValueRules = productRules?.find(
        (pr) => pr.ruleType === ProductRuleType.Price
    )

    const handlePriceChange = (v: string): void => {
        const value = Number(v)

        const newDownpaymentValue = isEmpty(downpaymentPercentage)
            ? 0
            : (value * downpaymentPercentage) / 100
        const newRestValue = isEmpty(restValuePercentage)
            ? 0
            : (value * restValuePercentage) / 100

        contractAsset &&
            setContractAsset({
                ...contractAsset,
                purchaseValue: value,
                downpayment: newDownpaymentValue,
                restValue: newRestValue,
            })
    }

    // Downpayment
    const downpaymentRules = getProductRuleByType(
        ProductRuleType.Downpayment,
        productRules
    )

    const getDefaultDownpayment = (): number => {
        let defaultDownpayment = 0
        if (!downpaymentRules?.targeted && !contractAsset?.downpayment) {
            defaultDownpayment = downpaymentRules?.defaultValue ?? 0
        } else if (!downpaymentRules?.targeted && contractAsset?.downpayment) {
            defaultDownpayment = contractAsset?.downpayment ?? 0
        } else if (
            downpaymentRules?.targeted &&
            downpaymentRules?.defaultValue &&
            purchaseValueRules?.defaultValue
        ) {
            defaultDownpayment = calculateValue(
                purchaseValueRules.defaultValue,
                downpaymentRules.defaultValue
            )
        }
        return defaultDownpayment
    }

    const getDefaultDownpaymentPercentage = (): number => {
        let defaultDownPercentage = 0
        if (downpaymentRules?.targeted) {
            defaultDownPercentage = downpaymentRules.defaultValue ?? 0
        } else if (contractAsset?.downpayment && contractAsset?.purchaseValue) {
            defaultDownPercentage = calculatePercentage(
                contractAsset.purchaseValue,
                contractAsset.downpayment
            )
        } else if (
            contractAsset &&
            contractAsset.downpayment === null &&
            downpaymentRules?.defaultValue &&
            purchaseValueRules?.defaultValue
        ) {
            defaultDownPercentage = calculatePercentage(
                purchaseValueRules.defaultValue,
                downpaymentRules.defaultValue
            )
        }
        return defaultDownPercentage
    }

    const handleDownpaymentChange = (v: string): void => {
        const value = Number(v)
        const newDownpaymentPercentageValue =
            value && contractAsset?.purchaseValue
                ? (value / contractAsset.purchaseValue) * 100
                : 0
        handleInputChange(value, 'downpayment')
        setDownpaymentPercentage(newDownpaymentPercentageValue)
    }

    const handleDownpaymentPercentageChange = (v: string): void => {
        const value = Number(v)
        const newDownpaymentValue = contractAsset?.purchaseValue
            ? (contractAsset.purchaseValue * value) / 100
            : 0
        handleInputChange(newDownpaymentValue, 'downpayment')
        setDownpaymentPercentage(value)
    }

    // RestValue
    const restValueRules = getProductRuleByType(
        ProductRuleType.RestValue,
        productRules
    )

    const getDefaultRestValue = (): number => {
        let defaultRestValue = 0
        if (!restValueRules?.targeted && !contractAsset?.restValue) {
            defaultRestValue = restValueRules?.defaultValue ?? 0
        } else if (!restValueRules?.targeted && contractAsset?.restValue) {
            defaultRestValue = contractAsset?.restValue ?? 0
        } else if (
            restValueRules?.targeted &&
            restValueRules?.defaultValue &&
            purchaseValueRules?.defaultValue
        ) {
            defaultRestValue = calculateValue(
                purchaseValueRules.defaultValue,
                restValueRules.defaultValue
            )
        }
        return defaultRestValue
    }

    const getDefaultRestValuePercentage = (): number => {
        let defaultRestPercentage = 0
        if (restValueRules?.targeted) {
            defaultRestPercentage = restValueRules.defaultValue ?? 0
        } else if (contractAsset?.restValue && contractAsset?.purchaseValue) {
            defaultRestPercentage = calculatePercentage(
                contractAsset.purchaseValue,
                contractAsset.restValue
            )
        } else if (
            contractAsset &&
            contractAsset.restValue === null &&
            restValueRules?.defaultValue &&
            purchaseValueRules?.defaultValue
        ) {
            defaultRestPercentage = calculatePercentage(
                purchaseValueRules.defaultValue,
                restValueRules.defaultValue
            )
        }
        return defaultRestPercentage
    }

    const handleRestValueChange = (v: string): void => {
        const value = Number(v)
        const newRestValuePercentageValue =
            value && contractAsset?.purchaseValue
                ? (value / contractAsset.purchaseValue) * 100
                : 0

        handleInputChange(value, 'restValue')
        setRestValuePercentage(newRestValuePercentageValue)
    }

    const handleRestValuePercentageChange = (v: string): void => {
        const value = Number(v)
        const newRestValue = contractAsset?.purchaseValue
            ? (contractAsset.purchaseValue * value) / 100
            : 0

        handleInputChange(newRestValue, 'restValue')
        setRestValuePercentage(value)
    }

    useEffect(() => {
        if (actionType === 'add') {
            setContractAsset({
                ...defaultContractAsset,
                purchaseValue: purchaseValueRules?.defaultValue || 0,
                downpayment: getDefaultDownpayment(),
                restValue: getDefaultRestValue(),
            })
            setDownpaymentPercentage(getDefaultDownpaymentPercentage())
            setRestValuePercentage(getDefaultRestValuePercentage())
        } else {
            setContractAsset(defaultContractAsset)
            setDownpaymentPercentage(
                calculatePercentage(
                    defaultContractAsset.purchaseValue,
                    defaultContractAsset.downpayment as number
                )
            )
            setRestValuePercentage(
                calculatePercentage(
                    defaultContractAsset.purchaseValue,
                    defaultContractAsset.restValue
                )
            )
        }
    }, [actionType])

    useEffect(() => {
        const formatting = getLocaleFormats(user?.formatting) || {}
        setFormat(formatting)
    }, [user])

    useEffect(() => {
        checkWarnings()
    }, [JSON.stringify(contractAsset)])

    useEffect(() => {
        fetchAssetTypes()
    }, [])

    function checkWarnings(): void {
        const localErrors: IAddAssetCustomErrors = {
            make: [],
            model: [],
            purchaseValue: [],
            downpayment: [],
            restValue: [],
            downpaymentPercentage: [],
            restValuePercentage: [],
            usageType: [],
            usagePriceCalculationMethod: [],
            pricePerUnitUnderUsageLimit: [],
            pricePerUnitOverUsageLimit: [],
            assetTypeData: [],
        }
        if (contractAsset) {
            if (
                downpaymentPercentage < 0.01 &&
                contractAsset.downpayment !== 0
            ) {
                localErrors.downpaymentPercentage.push(
                    'Value is lower than 0.01'
                )
                localErrors.downpayment.push('Downpayment value is too low')
            }
            if (restValuePercentage < 0.01 && contractAsset.restValue !== 0) {
                localErrors.restValuePercentage.push('Value is lower than 0.01')
                localErrors.restValue.push('Rest Value value is too low')
            }
        }
        setWarnings(localErrors)
    }

    function validateValues(event: any): void {
        event.preventDefault()

        if (contractAsset) {
            const { hasError, errorMessages } = validateAddAssetForm(
                contractAsset,
                productRules,
                isBaseAsset,
                translate
            )
            let additionalCheck = false
            if (isUsageEnabled) {
                if (isEmpty(contractAsset.usageType)) {
                    additionalCheck = true
                    errorMessages.usageType.push('Usage Type is required')
                }
                if (isEmpty(contractAsset.usagePriceCalculationMethod)) {
                    additionalCheck = true
                    errorMessages.usagePriceCalculationMethod.push(
                        'Usage price calculation method is required'
                    )
                }
            }

            setErrors(errorMessages)
            hasError && (additionalCheck = hasError)

            if (!hasError || !additionalCheck) {
                handleSubmit({
                    ...contractAsset,
                })
            }
        }
    }

    function buildErrorList(key: string, warning?: boolean): ReactElement {
        const list: any = warning ? { ...warnings } : { ...errors }
        return list?.[key] ? (
            <UnorderedList>
                {list?.[key].map((item: string, index: number) => (
                    <ListItem key={index} color={warning ? 'orange' : 'red'}>
                        {item}
                    </ListItem>
                ))}
            </UnorderedList>
        ) : (
            <></>
        )
    }

    // TODO: Create a service to update contract Asset and refactor child components to be independent from updating states in this component

    return (
        <Box>
            {contractAsset !== undefined && (
                <form onSubmit={validateValues}>
                    <SimpleGrid columns={2} spacing={4}>
                        <AssetBaseForm
                            format={format}
                            actionType={actionType}
                            buildErrorList={buildErrorList}
                            productRules={productRules}
                            isBaseAsset={isBaseAsset}
                            purchaseValueRules={purchaseValueRules}
                            downpaymentRules={downpaymentRules}
                            downpaymentPercentage={downpaymentPercentage}
                            restValueRules={restValueRules}
                            restValuePercentage={restValuePercentage}
                            existingAssetTypes={existingAssetTypes}
                            handlePriceChange={handlePriceChange}
                            handleDownpaymentChange={handleDownpaymentChange}
                            handleDownpaymentPercentageChange={
                                handleDownpaymentPercentageChange
                            }
                            handleRestValueChange={handleRestValueChange}
                            handleRestValuePercentageChange={
                                handleRestValuePercentageChange
                            }
                            handleInputChange={handleInputChange}
                            contractAsset={contractAsset}
                        />
                        {contractAsset.assetTypeData?.isMotor && (
                            <>
                                <AssetVehicleForm
                                    format={format}
                                    handleInputChange={handleInputChange}
                                    contractAsset={contractAsset}
                                    actionType={actionType}
                                />
                            </>
                        )}
                        {isUsageEnabled && (
                            <AssetUsageForm
                                handleInputChange={handleInputChange}
                                contractAsset={contractAsset}
                                actionType={actionType}
                                buildErrorList={buildErrorList}
                                productRules={productRules}
                            />
                        )}
                        {hasAccess(ModuleType.PartRegistration) && (
                            <AssetPartsForm
                                handleInputChange={handleInputChange}
                                contractAsset={contractAsset}
                                actionType={actionType}
                            ></AssetPartsForm>
                        )}
                        {hasLockIn && (
                            <LockInAssetFormFeature
                                contractAsset={contractAsset}
                                handleInputChange={handleInputChange}
                                actionType={actionType}
                                productRules={productRules}
                            />
                        )}
                    </SimpleGrid>

                    {actionType !== 'view' && (
                        <Button type="submit" mt={6}>
                            {translate('submit')}
                        </Button>
                    )}
                </form>
            )}
        </Box>
    )
}
